<template>
  <div class="mb-3 card">
    <v-card>
      <v-card-title class="pa-0 ma-0">
        <v-row>
          <v-col class="pt-3 mb-3 ma-0" cols="12">
            <div class="card-header-tab card-header">
              <div
                  class="card-header-title font-size-lg text-capitalize font-weight-normal"
              >
                <b-input-group width="100%">
                  <b-form-input
                      :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :placeholder="$t('datatables.lang_search')"
                      @focus="showTouchKeyboard"
                      v-model="search"
                  />
                </b-input-group>
              </div>
              <div class="btn-actions-pane-right actions-icon-btn">
                <b-dropdown
                    no-caret
                    right
                    toggle-class="btn-icon btn-icon-only"
                    variant="link"
                    :menu-class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
                >
                  <span slot="button-content"
                  ><i class="pe-7s-menu btn-icon-wrapper"></i
                  ></span>
                  <div>
                    <h6 class="dropdown-header" tabindex="-1">
                      {{ $t("generic.lang_Options") }}
                    </h6>

                    <!-- Excel Download: ONLY SELECTED ITEMS -->
                    <vue-excel-xlsx
                        :columns="excelColumns"
                        :data="selectedRows"
                        :filename="excelFileName"
                        :sheetname="'Sheet1'"
                        v-if="selectedRows.length > 0"
                    >
                      <button
                          :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']"
                          tabindex="0"
                          type="button"
                          v-if="selectedRows.length > 0"
                      >
                        <font-awesome-icon
                            class="dropdown-icon success--text"
                            :icon="['fal', 'file-excel']"
                        />
                        <span>Excel</span>
                      </button>
                    </vue-excel-xlsx>

                    <vue-excel-xlsx
                        v-else
                        :columns="excelColumns"
                        :data="filteredDatatableEntries"
                        :filename="excelFileName"
                        :sheetname="'Sheet1'"
                    >
                      <button :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']" tabindex="0" type="button">
                        <font-awesome-icon
                            class="dropdown-icon success--text"
                            :icon="['fal', 'file-excel']"
                        />
                        <span>Excel</span>
                      </button>
                    </vue-excel-xlsx>

                    <button
                        :disabled="selectedRows.length < 1"
                        @click="entriesDelete"
                        :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']"
                        tabindex="0"
                        type="button"
                    >
                      <v-icon class="dropdown-icon" color="error">
                        {{ $t("generic.lang_delete") }}
                      </v-icon>
                      <span>{{ $t("generic.lang_delete") }}</span>
                    </button>

                    <button
                        @click="bulkPriceUpdate"
                        :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']"
                        tabindex="0"
                        type="button"
                    >
                      <v-icon class="dropdown-icon" color="primary">
                        mdi-currency-usd
                      </v-icon>
                      <span>{{ $t("erp.lang_override_prices") }}</span>
                    </button>

                    <button
                        @click="rearrangeGarnishByAlphabet"
                        :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']"
                        tabindex="0"
                        type="button"
                    >
                      <v-icon class="dropdown-icon" color="warning">
                        mdi-sort-alphabetical-descending
                      </v-icon>
                      <span>{{ $t("erp.lang_rerange_garnish_alphabet") }}</span>
                    </button>

                  </div>
                </b-dropdown>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mx-0 px-0">
        <v-row>
          <v-col class="px-12" cols="12">
            <v-select
                outlined
                style="margin-top: 15px; margin-bottom: -15px"
                chips
                dense
                v-model="selectedGarnishGroups"
                @input="getDataFromApi"
                :items="this.garnishGroups"
                deletable-chips
                item-text="name"
                item-value="uuid"
                :label="$t('erp.lang_garnishGroups')"
                multiple
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-data-table
                :footer-props="footerProps"
                :headers="filteredDatatableHeaders"
                :items="filteredDatatableEntries"
                :loading="loading"
                sortBy="position"
                :options.sync="pagination"
                :server-items-length="total"
                show-select
                footer-props.items-per-page-text="Einträge pro Seite"
                v-model="selectedRows"
            >
              <template v-slot:body="props">
                <draggable
                    :list="props.items"
                    tag="tbody"
                    v-bind="dragOptions"
                    @end="changePosition($event)"
                >
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td>
                      <v-simple-checkbox
                          :ripple="false"
                          @click="props.isSelected(item)?props.select(item,false):props.select(item,true)"
                          :value="props.isSelected(item)"
                      />
                    </td>
                    <td>
                      <font-awesome-icon
                          :icon="['fas', 'grip-vertical']"
                          style="color: lightgray; cursor: pointer"
                          class="page__grab-icon text-lighter handle"
                      />
                    </td>
                    <td>
                      <!--                      {{ item.position }}-->

                      <v-text-field @change="updatePosition(item)" :min="1" type="number" dense outlined hide-details
                                    v-model="item.position"></v-text-field>
                    </td>

                    <td>
                      {{ item.itemID }}
                    </td>

                    <td>{{ item.itemName }}</td>

                    <td>{{ item.internWareID }}</td>

                    <td>{{ item.vknet | currency }}</td>

                    <td>{{ item.garnishGroupName }}</td>

                    <td>
                      <v-btn
                          v-if="
                          $store.getters['permissions/checkPermission'](
                            'ware_edit'
                          )
                        "
                          @click="editEntry(item)"
                          class="mx-auto"
                          color="primary"
                          icon
                          text
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>

                      <v-btn
                          v-if="
                          $store.getters['permissions/checkPermission'](
                            'ware_delete'
                          )
                        "
                          @click="entryDelete(item)"
                          class="mx-auto"
                          color="error"
                          icon
                          text
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
          id="onScreenKeyboard"
          class="internalWidthExpanded"
          :options="touchKeyboard.options"
          :defaultKeySet="touchKeyboard.keySet"
          v-if="touchKeyboard.visible"
          :layout="touchKeyboard.layout"
          :cancel="hideTouchKeyboard"
          :accept="hideTouchKeyboard"
          :input="touchKeyboard.input"
      />
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import Datatable from "../../datatable/Datatable";
import mixin from "../../../mixins/KeyboardMixIns";
import draggable from "vuedraggable";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faGripVertical} from "@fortawesome/pro-solid-svg-icons";

library.add(faGripVertical);

export default {
  components: {
    Datatable,
    draggable,
    "font-awesome-icon": FontAwesomeIcon,
  },
  mixins: [mixin],
  data() {
    return {
      excelFileName: "garnish excel",
      ENDPOINTS,
      loading: false,
      drag: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [],
      search: "",
      total: 0,
      pagination: {},
      footerProps: {"items-per-page-options": [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      tab: 0,
      garnishGroups: [],
      garnishGroup: null,
      selectedGarnishGroups: null,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: "",
          value: "drag_icon",
        },
        {
          text: "ID",
          align: "start",
          value: "id",
          width: 80,
          hide: true,
        },
        {
          text: this.$t("generic.lang_position"),
          align: "start",
          value: "position",
          //hide: true,
        },
        {text: this.$t("erp.lang_ware_create_id"), value: "itemID"},
        {
          text: this.$t("erp.lang_posItemName"),
          value: "itemName",
          sort: "asc",
        },
        {text: this.$t("erp.internal_wareNo"), value: "internWareID"},
        {text: this.$t("erp.lang_netPurchasePricePerUnit"), value: "vknet"},
        {text: this.$t("erp.lang_garnishGroup"), value: "garnishGroupName"},
        {text: this.$t("erp.lang_garnishGroup") + " uuid", value: "garnishGroupUUID", hide: true},
        {text: "", value: "crud"},
      ],
      datatableAttachSearch: [
        {
          text: this.$t("erp.lang_garnishGroups"),
          value: "garnishGroups",
          searchType: "multiselect",
          searchCol: 6,
        },
      ],
      excelColumns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t("generic.lang_position"),
          field: "position",
        },
        {
          label: this.$t("erp.lang_ware_create_id"),
          field: "itemID",
        },
        {
          label: this.$t("erp.lang_posItemName"),
          field: "itemName",
        },
        {
          label: this.$t("erp.internal_wareNo"),
          field: "internWareID",
        },
        {
          label: this.$t("erp.lang_netPurchasePricePerUnit"),
          field: "vknet",
        },
        {label: this.$t("erp.lang_garnishGroup") + " uuid", field: "garnishGroupUUID"},
      ],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }
        headers.push(header);
      });

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};
        let index = 0;
        this.datatableHeaders.forEach((header) => {
          if (
              header.value !== "drag_icon" &&
              header.value !== "crud" &&
              header.value !== "select"
          ) {
            if (header.value === "price") {
              row[header.value] = Number(
                  data[index].toString().replace("EUR", "").replace(",", ".")
              );
            } else {
              row[header.value] = data[index];
            }
          } else {
            return;
          }
          index++;
        });
        dataToRender.push(row);
      });
      return dataToRender;
    },
    searchPayload() {
      return this.garnishGroups.map(({id}) => id);
      /* .map((itemgroup) => {
          return {
            id: itemgroup.uuid,
            name: itemgroup.name,
          };
        })
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });*/
    },
  },
  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    rearrangeGarnishByAlphabet() {
      this.$swal({
        title: this.$t("erp.lang_rerange_garnish_alphabet"),
        text: this.$t("erp.lang_rerange_garnish_alphabet_confirmation"),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_apply'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios
              .post(ENDPOINTS.ERP.GARNISH.RERANGE_ALPHABET, {})
              .then((res) => {
                if (res.status === 200) {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_actionSuccessful"),
                    color: "success",
                  });
                  this.getDataFromApi();
                } else {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                  });
                }
              }).catch(() => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    bulkPriceUpdate() {
      this.$swal({
        title: this.$t("erp.lang_override_prices"),
        text: this.$t("erp.lang_override_prices_confirmation"),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_apply'),
        preConfirm: () => {
          this.axios
              .post(ENDPOINTS.ERP.GARNISH.BULK_PRICE_OVERRIDE, {})
              .then((res) => {
                if (res.status === 200) {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_actionSuccessful"),
                    color: "success",
                  });
                } else {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                  });
                }
              }).catch(() => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    changePosition(event) {
      let item = this.filteredDatatableEntries[event.oldIndex];
      let itemReplaced = this.filteredDatatableEntries[event.newIndex];
      if (event.oldIndex < event.newIndex) {
        if (item.id !== itemReplaced.id)
          item.position = parseInt(Number(itemReplaced.position)) + 1;
      } else if (event.oldIndex > event.newIndex) {
        if (item.id !== itemReplaced.id)
          if (parseInt(Number(itemReplaced.position)) - 1 === 0) {
            item.position = 1;
          } else {
            item.position = parseInt(Number(itemReplaced.position)) - 1;
          }
      }
      if (item.id !== itemReplaced.id) {
        this.updatePosition(item);
      }
    },
    updatePosition(item) {
      this.axios
          .post(ENDPOINTS.ERP.GARNISH.CHANGE_POSITION, {
            garnish_id: item.id,
            position: item.position,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_garnishDeleted"),
                color: "success",
              });

              this.getDataFromApi();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          });
    },
    async getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart:
            this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(
            (header) => header.value === this.pagination.sortBy[0]
        );
        index -= 1;
        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? "desc" : "asc";
      }

      params["attached_garnishGroups"] = this.selectedGarnishGroups;
      /* SPECIFY ADDITIONAL PARAMS
       * params['customerID'] = parseInt(this.$props.customerId);
       *
       * */

      this.axios
          .get(ENDPOINTS.DATATABLES.ERP.GARNISH, {
            params: params,
          })
          .then((res) => {
            this.total = parseInt(res.data.iTotalDisplayRecords);
            this.dataToShow = res.data.aaData;
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
            this.dataToShow = [];
          })
          .finally((fin) => {
            this.loading = false;
          });
    },
    editEntry(entry) {
      this.id = entry.id;
      this.$router.push({
        name: "erp.baseData.garnish.edit",
        params: {id: this.id},
      });
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t("erp.lang_deleteGarnish"),
        text: this.$t("erp.lang_deleteGarnishText"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios
              .post(ENDPOINTS.ERP.GARNISH.DELETE, {
                deleteItemID: idsToDelete,
              })
              .then((res) => {
                if (res.data.status === "SUCCESS") {
                  Events.$emit("showSnackbar", {
                    message: this.$t("erp.lang_garnishDeleted"),
                    color: "success",
                  });

                  self.resetData();
                  self.getDataFromApi();
                } else {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                  });
                }
              });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    resetData: function () {
      this.id = null;
    },
    showList: function () {
      this.resetData();
    },
    entryDelete(entry) {
      this.id = entry.id;

      this.deleteData();
    },
    entriesDelete(entries) {
      let ids = [];

      this.selectedRows.forEach((row) => {
        ids.push(row['id']);
      });
      this.deleteData(ids);
    },
    getGarnishGroups() {
      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") this.garnishGroups = res.data.groups;
      });
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getGarnishGroups();
  },
};
</script>
